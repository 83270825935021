import ApiFetcher from "@/services/api-fetcher"
import type {
  Asset,
  AssetDocument,
  AssetGroup,
  AssetGroupCreate,
  AssetKind,
  AssetPrice,
  AssetsSummary,
  AssetStatus,
  PublicAsset,
  AssetVersion,
} from "@common/models/asset"
import { ASSET_STATUS } from "@common/models/asset"
import type { DeviceAuditTrail } from "@common/models/audit"
import { AxiosError } from "axios"
import type { AssetTimeSeriesUploadError, AssetTimeSeriesUploadInput, TimeSeriesEntry } from "./assetTimeSeries.state"
import { Account, User } from "@common/models/models"

type ApiAssetsSummary = {
  countsByStatus: Record<AssetStatus, number>
  deviceCount: number
}

export type AssetUploadError = {
  loc: string[]
  msg: string
  type: string
}

export type AssetUploadResult = {
  device: Asset | null
  errors: AssetUploadError[] | null
  line: number
}

export const REGISTRATION_RIGHTS = [
  {
    name: "environmental_attributes_owner_self_assigned",
    short: "Self-assigned",
    description:
      "I own one or more Qualifying Assets and the right to register such asset(s) on WEATs, as well as the sole right to any EACs generated by such asset(s).",
  },
  {
    name: "device_owner",
    short: "Asset Owner",
    description:
      "I own one or more Qualifying Assets and am assigning the right to register such asset(s) on WEATS to the legal entity selected below. I retain the exclusive right to any EACs generated by such asset(s).",
  },
  {
    name: "environmental_attributes_owner",
    short: "EA Owner",
    description:
      "I own one or more Qualifying Assets and am assigning the right to register such asset(s) on WEATS, as well as the exclusive right to any EACs generated by such asset(s), to the legal entity selected below.",
  },
  {
    name: "contract_party_environmental_attributes_contract_party_assigned",
    short: "Assigned Owner",
    description:
      "I have contracted with the owner(s) of one or more Qualifying Assets for the right to register such asset(s) on WEATS, as well as the exclusive right to all EACs generated by such asset(s)",
  },
  {
    name: "contract_party_environmental_attributes_owner_assigned",
    short: "Owner retains rights",
    description:
      "I have contracted with the owner(s) of one or more Qualifying Assets for the right to register such asset(s) on WEATS. The owner(s) of such asset(s) retain the exclusive right to all EACs generated by such asset(s).",
  },
]

type AssetLocation = Asset["coordinates"] & {
  color?: string
  strokeColor?: string
  metadata: Asset
}

export const mapAssetsToLocations = (assets: Asset[]) => {
  return assets.reduce((acc, asset) => {
    const { coordinates, status } = asset
    return [
      ...acc,
      {
        ...coordinates,
        color: ASSET_STATUS[status]?.color,
        strokeColor: ASSET_STATUS[status]?.strokeColor,
        metadata: asset,
      },
    ]
  }, [] as AssetLocation[])
}

export interface AttestationCreate {
  registrationRights: string
  signature: string
}

export interface Attestation extends AttestationCreate {
  id: number
  account: Account
  user: User
  createdTime: string
}

type AssetUploadGlobalErrorResult = {
  detail: string
}

export type AssetTimeSeriesUploadResult = TimeSeriesEntry[]

type AssetTimeSeriesUploadGlobalErrorResult = {
  detail: string | AssetTimeSeriesUploadError[]
}

export default class AssetService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  async getAsset(assetId: number): Promise<Asset | undefined> {
    return await this.fetcher.httpGet<Asset>(`/devices/${assetId}`, {})
  }

  async getAssetAdmin(assetId: number): Promise<Asset> {
    return await this.fetcher.httpGet<Asset>(`/devices/${assetId}/admin`, {})
  }

  async listAssets(options: { excludedGroupId?: number; groupId?: number | null; status?: AssetStatus; ungrouped?: boolean; url?: string }) {
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/devices")
      const parsedUrl = url.slice(pathIndex)
      return await this.fetcher.httpGetPaginated<Asset[]>(parsedUrl, queryOptions)
    }
    return await this.fetcher.httpGetPaginated<Asset[]>(`/devices`, queryOptions)
  }

  async listAssetsAdmin(options: { status?: AssetStatus; url?: string }) {
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/devices/admin")
      const parsedUrl = url.slice(pathIndex)
      return await this.fetcher.httpGetPaginated<Asset[]>(parsedUrl, queryOptions)
    }
    return await this.fetcher.httpGetPaginated<Asset[]>(`/devices/admin`, queryOptions)
  }

  async listAssetsPublic(options: { storyId?: number; url?: string }) {
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/devices/public")
      const parsedUrl = url.slice(pathIndex)
      return await this.fetcher.httpGetPaginated<PublicAsset[]>(parsedUrl, queryOptions)
    }
    return await this.fetcher.httpGetPaginated<PublicAsset[]>(`/devices/public`, queryOptions)
  }

  async submitAssetRegistration(attestation: AttestationCreate) {
    return await this.fetcher.httpPost<Attestation>("/attestations", attestation)
  }

  async listAttestations(accountId: number) {
    return await this.fetcher.httpGet<Attestation[]>("/attestations", { accountId })
  }

  async submitAssetReview(assetId: number, review: { status: AssetStatus; reviewNotesExternal?: string; reviewNotesInternal?: string }) {
    return await this.fetcher.httpPost(`/devices/${assetId}/reviews`, review)
  }

  async getAssetsSummary(): Promise<AssetsSummary> {
    const apiAssetsSummary = await this.fetcher.httpGet<ApiAssetsSummary>("/devices/summary", {})
    const { countsByStatus, deviceCount: assetCount } = apiAssetsSummary
    return { assetCount, countsByStatus }
  }

  async getAssetsAdminSummary(): Promise<AssetsSummary> {
    const apiAssetsSummary = await this.fetcher.httpGet<ApiAssetsSummary>("/devices/admin/summary", {})
    const { countsByStatus, deviceCount: assetCount } = apiAssetsSummary
    return { assetCount, countsByStatus }
  }

  async getAssetsAuditTrail(assetId: number): Promise<DeviceAuditTrail> {
    return await this.fetcher.httpGet<DeviceAuditTrail>(`/devices/${assetId}/audit_trail`, {})
  }

  async uploadAssetDocument(assetId: number, data: Partial<AssetDocument>, fileData: File) {
    return await this.fetcher.httpUpload<AssetDocument>(`/devices/${assetId}/documents`, data, fileData)
  }

  async listAssetDocuments(assetId: number) {
    return await this.fetcher.httpGet<AssetDocument[]>(`/devices/${assetId}/documents`, {})
  }

  async getAssetDocument(assetId: number, documentId: number) {
    return await this.fetcher.httpGet<AssetDocument>(`/devices/${assetId}/documents/${documentId}`, {})
  }

  async deleteAssetDocument(assetId: number, documentId: number) {
    return await this.fetcher.httpDelete(`/devices/${assetId}/documents/${documentId}`, {})
  }

  async setAssetPrice(assetId: number, priceUpdate: AssetPrice) {
    return await this.fetcher.httpPut(`/devices/${assetId}/price`, priceUpdate)
  }

  async deleteAccountAssets(accountId: number) {
    return await this.fetcher.httpDelete(`/devices/account/${accountId}`)
  }

  async deleteAssetPrice(assetId: number) {
    return await this.fetcher.httpDelete(`/devices/${assetId}/price`)
  }

  async setAssetPriceBulk(assetIds: number[], priceUpdate: AssetPrice) {
    const update = assetIds.map((assetId) => {
      return {
        deviceId: assetId,
        op: "setPrice",
        price: priceUpdate,
      }
    })
    return await this.fetcher.httpPatch(`/devices`, update)
  }

  async deleteAssetPriceBulk(assetIds: number[]) {
    const update = assetIds.map((assetId) => {
      return {
        deviceId: assetId,
        op: "deletePrice",
      }
    })
    return await this.fetcher.httpPatch(`/devices`, update)
  }

  async deleteAsset(assetId: number) {
    return await this.fetcher.httpDelete(`/devices/${assetId}`, {})
  }

  async setAssetStoryBulkAdmin(assetIds: number[], storyId: number | null) {
    const update = assetIds.map((assetId) => {
      return {
        deviceId: assetId,
        op: "setStory",
        story: {
          storyId,
        },
      }
    })
    return await this.fetcher.httpPatch(`/devices/admin`, update)
  }

  async validateUploadedAssets(kind: AssetKind, fileData: File): Promise<AssetUploadResult[] | AssetUploadGlobalErrorResult> {
    try {
      return await this.fetcher.httpUpload(`/devices/csv`, { kind, validateOnly: true }, fileData)
    } catch (error) {
      const axiosError = error as AxiosError
      if (axiosError.code === AxiosError.ERR_BAD_REQUEST) {
        return (axiosError.response?.data || []) as AssetUploadResult[] | AssetUploadGlobalErrorResult
      }
      return { detail: "There was an error processing this file. Please try again." }
    }
  }

  async validateAdminUploadedAssets(accountId: number, kind: AssetKind, fileData: File): Promise<AssetUploadResult[] | AssetUploadGlobalErrorResult> {
    try {
      return await this.fetcher.httpUpload(`/devices/csv/admin`, { accountId, kind, validateOnly: true }, fileData)
    } catch (error) {
      const axiosError = error as AxiosError
      if (axiosError.code === AxiosError.ERR_BAD_REQUEST) {
        return (axiosError.response?.data || []) as AssetUploadResult[] | AssetUploadGlobalErrorResult
      }
      return { detail: "There was an error processing this file. Please try again." }
    }
  }

  async uploadAssets(kind: AssetKind, fileData: File): Promise<AssetUploadResult[]> {
    return await this.fetcher.httpUpload<AssetUploadResult[]>(`/devices/csv`, { kind, validateOnly: false }, fileData)
  }

  async uploadAdminAssets(accountId: number, kind: AssetKind, fileData: File): Promise<AssetUploadResult[]> {
    return await this.fetcher.httpUpload<AssetUploadResult[]>(`/devices/csv/admin`, { accountId, kind, validateOnly: false }, fileData)
  }

  async validateUploadedAssetTimeSeries(
    props: AssetTimeSeriesUploadInput
  ): Promise<AssetTimeSeriesUploadResult | AssetTimeSeriesUploadGlobalErrorResult> {
    const { assetId, fileData, timezone, ...other } = props
    try {
      return await this.fetcher.httpUpload(`/devices/${assetId}/timeseries`, { timezone, validate_only: true, ...other }, fileData)
    } catch (error) {
      const axiosError = error as AxiosError
      if (axiosError.code === AxiosError.ERR_BAD_REQUEST) {
        return (axiosError.response?.data || []) as AssetTimeSeriesUploadResult | AssetTimeSeriesUploadGlobalErrorResult
      }
      return { detail: "There was an error processing this file. Please try again." }
    }
  }

  async uploadAssetTimeSeries(props: AssetTimeSeriesUploadInput): Promise<AssetTimeSeriesUploadResult> {
    const { assetId, fileData, timezone, ...other } = props
    return await this.fetcher.httpUpload(`/devices/${assetId}/timeseries`, { timezone, validate_only: false, ...other }, fileData)
  }

  // Asset Groups
  async createAssetGroup(assetGroup: AssetGroupCreate) {
    return await this.fetcher.httpPost<AssetGroup>(`/devices/groups`, assetGroup)
  }

  async updateAssetGroup(groupId: number, assetGroup: { name: string }) {
    return await this.fetcher.httpPut<AssetGroup>(`/devices/groups/${groupId}`, assetGroup)
  }

  async deleteAssetGroup(groupId: number) {
    return await this.fetcher.httpDelete(`/devices/groups/${groupId}`, {})
  }

  async addAssetsToAssetGroup(groupId: number, assetIds: number[]) {
    return await this.fetcher.httpPost<AssetGroup>(`/devices/groups/${groupId}`, { deviceIds: assetIds })
  }

  async removeAssetsFromAssetGroup(groupId: number, assetIds: number[]) {
    // TODO: Switch to a bulk endpoint if/when available
    return Promise.allSettled(assetIds.map((assetId) => this.removeAssetFromAssetGroup(groupId, assetId)))
  }

  async removeAssetFromAssetGroup(groupId: number, assetId: number) {
    return await this.fetcher.httpDelete<AssetGroup>(`/devices/groups/${groupId}/${assetId}`, {})
  }

  async listAssetGroups(options: { per_page?: number; url?: string }) {
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/devices/groups")
      const parsedUrl = url.slice(pathIndex)
      return await this.fetcher.httpGetPaginated<AssetGroup[]>(parsedUrl, queryOptions)
    }
    return await this.fetcher.httpGetPaginated<AssetGroup[]>(`/devices/groups`, queryOptions)
  }

  async getAssetGroup(groupId: number) {
    return await this.fetcher.httpGet<AssetGroup>(`/devices/groups/${groupId}`, {})
  }

  async triggerMinting(assetId: number) {
    return await this.fetcher.httpPut(`/devices/${assetId}/mint`, {})
  }

  async listAssetVersions(options: { assetId?: number; url?: string }) {
    if (options.url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = options.url.indexOf("/devices")
      const parsedUrl = options.url.slice(pathIndex)
      return await this.fetcher.httpGetPaginated<AssetVersion[]>(parsedUrl, {})
    }
    return await this.fetcher.httpGetPaginated<AssetVersion[]>(`/devices/${options.assetId}/versions`, {})
  }
}
