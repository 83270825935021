<template>
  <div class="flex items-center justify-between gap-2">
    <h2 class="text-heading-4 mb-4 max-w-3xl break-words">Current Audit Status: {{ asset.auditStatus }}</h2>
    <div v-if="pollInterval !== undefined" class="flex items-center">
      Polling for audit changes
      <WcLoadingSpinner class="h-8 w-16" />
    </div>
  </div>
  <div v-if="!isLoading && auditTrail !== undefined && pollInterval === undefined">
    <p>
      # Informational: {{ totalInfoCount }}, # Warnings: {{ totalWarningCount }}, # Errors: {{ totalErrorCount }}, # Needs Acceptance:
      {{ totalNeedsAcceptanceCount }}
    </p>
    <div class="text-subheading-2 mb-6 mt-10 flex flex-row bg-neutral-10 px-4">
      <button
        class="tab"
        :class="{ 'active-tab': selectedCategory === AuditStatusCategory.MeasurementDevice }"
        @click="selectedCategory = AuditStatusCategory.MeasurementDevice">
        Measurement Device Results ({{ auditTrail?.measurementDeviceAuditResults?.issues?.length || 0 }})
      </button>
      <button
        class="tab"
        :class="{ 'active-tab': selectedCategory === AuditStatusCategory.DeviceSavings }"
        @click="selectedCategory = AuditStatusCategory.DeviceSavings">
        Device Savings Results ({{ auditTrail?.deviceSavingsAuditResults?.issues?.length || 0 }})
      </button>
      <button
        class="tab"
        :class="{ 'active-tab': selectedCategory === AuditStatusCategory.Device }"
        @click="selectedCategory = AuditStatusCategory.Device">
        Device Results ({{ auditTrail?.deviceAuditResults?.issues?.length || 0 }})
      </button>
      <button
        class="tab"
        :class="{ 'active-tab': selectedCategory === AuditStatusCategory.TimeSeries }"
        @click="selectedCategory = AuditStatusCategory.TimeSeries">
        Time Series Results ({{ auditTrail?.timeSeriesAuditResults?.issues?.length || 0 }})
      </button>
    </div>
    <div v-if="selectedCategory === AuditStatusCategory.MeasurementDevice">
      <p class="text-body-2 mb-6">
        # Informational: {{ measurementDeviceInfoCount }}, # Warnings: {{ measurementDeviceWarningCount }}, # Errors:
        {{ measurementDeviceErrorCount }}, # Needs Acceptance: {{ measurementDeviceNeedsAcceptanceCount }}
      </p>
      <div v-if="auditTrail.measurementDeviceAuditResults !== undefined && auditTrail.measurementDeviceAuditResults.issues.length > 0">
        <table v-if="auditTrail.measurementDeviceAuditResults !== undefined" class="text-body-2 w-full text-left">
          <tr
            v-for="issue in auditTrail.measurementDeviceAuditResults.issues"
            :key="issue.auditName.concat(issue.description)"
            :class="{
              'bg-info-lightbg': issue.severity === 'info',
              'bg-warning-lightbg': issue.severity === 'warning',
              'bg-error-lightbg': issue.severity === 'error',
            }"
            class="h-[52px] border-b border-neutral-20 align-middle">
            <th class="w-[334px] min-w-[334px] pr-2 font-normal">
              <Icon :icon="`${issue.isAccepted ? 'wc-carbon:checkmark' : 'mdi:alert-circle-outline'}`" class="inline-flex" />
              {{ issue.auditName }}
            </th>
            <td class="my-10">{{ issue.description }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="selectedCategory === AuditStatusCategory.DeviceSavings">
      <p class="text-body-2 mb-6">
        # Informational: {{ deviceSavingsInfoCount }}, # Warnings: {{ deviceSavingsWarningCount }}, # Errors: {{ deviceSavingsErrorCount }}, # Needs
        Acceptance: {{ deviceSavingsNeedsAcceptanceCount }}
      </p>
      <div v-if="auditTrail.deviceSavingsAuditResults !== undefined && auditTrail.deviceSavingsAuditResults.issues.length > 0">
        <table v-if="auditTrail.deviceSavingsAuditResults !== undefined" class="text-body-2 w-full text-left">
          <tr
            v-for="issue in auditTrail.deviceSavingsAuditResults.issues"
            :key="issue.auditName.concat(issue.description)"
            :class="{
              'bg-info-lightbg': issue.severity === 'info',
              'bg-warning-lightbg': issue.severity === 'warning',
              'bg-error-lightbg': issue.severity === 'error',
            }"
            class="h-[52px] border-b border-neutral-20 align-middle">
            <th class="w-[334px] min-w-[334px] pr-2 font-normal">
              <Icon :icon="`${issue.isAccepted ? 'wc-carbon:checkmark' : 'mdi:alert-circle-outline'}`" class="inline-flex" />
              {{ issue.auditName }}
            </th>
            <td class="my-10">{{ issue.description }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="selectedCategory === AuditStatusCategory.Device">
      <p class="text-body-2 mb-6">
        # Informational: {{ deviceInfoCount }}, # Warnings: {{ deviceWarningCount }}, # Errors: {{ deviceErrorCount }}, # Needs Acceptance:
        {{ deviceNeedsAcceptanceCount }}
      </p>
      <div v-if="auditTrail.deviceAuditResults !== undefined && auditTrail.deviceAuditResults.issues.length > 0">
        <table v-if="auditTrail.deviceAuditResults !== undefined" class="text-body-2 w-full text-left">
          <tr
            v-for="issue in auditTrail.deviceAuditResults.issues"
            :key="issue.auditName.concat(issue.description)"
            :class="{
              'bg-info-lightbg': issue.severity === 'info',
              'bg-warning-lightbg': issue.severity === 'warning',
              'bg-error-lightbg': issue.severity === 'error',
            }"
            class="h-[52px] border-b border-neutral-20 align-middle">
            <th class="w-[334px] min-w-[334px] pr-2 font-normal">
              <Icon :icon="`${issue.isAccepted ? 'wc-carbon:checkmark' : 'mdi:alert-circle-outline'}`" class="inline-flex" />
              {{ issue.auditName }}
            </th>
            <td class="my-10">{{ issue.description }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="selectedCategory === AuditStatusCategory.TimeSeries">
      <p class="text-body-2 mb-6">
        # Informational: {{ timeSeriesInfoCount }}, # Warnings: {{ timeSeriesWarningCount }}, # Errors: {{ timeSeriesErrorCount }}, # Needs
        Acceptance: {{ timeSeriesNeedsAcceptanceCount }}
      </p>
      <div v-if="auditTrail.timeSeriesAuditResults !== undefined && auditTrail.timeSeriesAuditResults.issues.length > 0">
        <table v-if="auditTrail.timeSeriesAuditResults !== undefined" class="text-body-2 w-full text-left">
          <tr
            v-for="issue in auditTrail.timeSeriesAuditResults.issues"
            :key="issue.auditName.concat(issue.description)"
            :class="{
              'bg-info-lightbg': issue.severity === 'info',
              'bg-warning-lightbg': issue.severity === 'warning',
              'bg-error-lightbg': issue.severity === 'error',
            }"
            class="h-[52px] border-b border-neutral-20 align-middle">
            <th class="w-[334px] min-w-[334px] pr-2 font-normal">
              <Icon :icon="`${issue.isAccepted ? 'wc-carbon:checkmark' : 'mdi:alert-circle-outline'}`" class="inline-flex" />
              {{ issue.auditName }}
            </th>
            <td class="my-10">{{ issue.description }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <pre v-if="isLoading">Loading Audit Trail...</pre>
  <pre v-if="hasError">Error retrieving audit trail.</pre>
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue"
import { Icon } from "@iconify/vue"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import { useAssetService } from "@/services/service-container"
import type { Asset } from "@common/models/asset"
import type { DeviceAuditTrail } from "@common/models/audit"

enum AuditStatusCategory {
  MeasurementDevice = "measurementDevice",
  DeviceSavings = "deviceSavings",
  Device = "device",
  TimeSeries = "timeSeries",
}

// This emit is not implemented, but defined here in order to follow the
// interface between AdminAssetView and its tabs
defineEmits(["on-asset-update-state"])

const assetService = useAssetService()

const props = defineProps<{ asset: Asset }>()
const asset = ref<Asset>(props.asset)
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const auditTrail = ref<DeviceAuditTrail>()
const selectedCategory = ref<AuditStatusCategory>(AuditStatusCategory.MeasurementDevice)

const measurementDeviceInfoCount = ref(0)
const measurementDeviceWarningCount = ref(0)
const measurementDeviceErrorCount = ref(0)
const measurementDeviceNeedsAcceptanceCount = ref(0)

const deviceInfoCount = ref(0)
const deviceWarningCount = ref(0)
const deviceErrorCount = ref(0)
const deviceNeedsAcceptanceCount = ref(0)

const timeSeriesInfoCount = ref(0)
const timeSeriesWarningCount = ref(0)
const timeSeriesErrorCount = ref(0)
const timeSeriesNeedsAcceptanceCount = ref(0)

const deviceSavingsInfoCount = ref(0)
const deviceSavingsWarningCount = ref(0)
const deviceSavingsErrorCount = ref(0)
const deviceSavingsNeedsAcceptanceCount = ref(0)

const totalInfoCount = ref(0)
const totalWarningCount = ref(0)
const totalErrorCount = ref(0)
const totalNeedsAcceptanceCount = ref(0)

const pollInterval = ref<NodeJS.Timeout>()
const pollCount = ref(0)
const POLL_FREQUENCY = 3000 // 3 seconds
const MAX_POLL_ATTEMPTS = 20 // Stop after 1 minute (20 * 3 seconds)

onBeforeMount(() => {
  loadAssetData()
  loadAuditTrail()
})

onBeforeUnmount(() => {
  clearAuditStatusPolling()
})

const loadAssetData = async () => {
  try {
    asset.value = await assetService.getAssetAdmin(asset.value.id)
  } catch (error) {
    console.error(`There was an error loading asset ${asset.value.id}`, error)
  }
}

const loadAuditTrail = async () => {
  isLoading.value = true
  try {
    auditTrail.value = await assetService.getAssetsAuditTrail(asset.value.id)
    loadSeverityCounts(auditTrail)
  } catch (error) {
    hasError.value = true
    console.error("Error loading audit trail", error)
  }
  isLoading.value = false
}

const clearAuditStatusPolling = () => {
  if (pollInterval.value) {
    clearInterval(pollInterval.value)
    pollInterval.value = undefined
  }
}

const startAuditStatusPolling = () => {
  clearAuditStatusPolling()

  pollInterval.value = setInterval(async () => {
    pollCount.value++

    if (pollCount.value >= MAX_POLL_ATTEMPTS) {
      clearAuditStatusPolling()
      console.warn("Polling timed out after", MAX_POLL_ATTEMPTS, "attempts")
      return
    }

    await loadAssetData()
    if (asset.value.auditStatus !== "running") {
      clearAuditStatusPolling()
      await loadAuditTrail()
    }
  }, POLL_FREQUENCY)
}

// Start/stop polling based on asset audit status
watch(
  () => asset.value?.auditStatus,
  (auditStatus) => {
    if (auditStatus === "running") {
      pollCount.value = 0
      startAuditStatusPolling()
    } else if (pollInterval.value) {
      clearAuditStatusPolling()
    }
  },
  { immediate: true }
)

const getSeverityCount = (auditResults: any, severity: string) => {
  return auditResults?.issues?.filter((issue: any) => issue.severity === severity).length || 0
}

const getNeedsAcceptanceCount = (auditResults: any) => {
  return auditResults?.issues?.filter((issue: any) => issue.isAccepted === false).length || 0
}

const loadSeverityCounts = (auditTrail: any) => {
  // Measurement Device Audit Result Counts
  measurementDeviceInfoCount.value = getSeverityCount(auditTrail.value?.measurementDeviceAuditResults, "info")
  measurementDeviceWarningCount.value = getSeverityCount(auditTrail.value?.measurementDeviceAuditResults, "warning")
  measurementDeviceErrorCount.value = getSeverityCount(auditTrail.value?.measurementDeviceAuditResults, "error")
  measurementDeviceNeedsAcceptanceCount.value = getNeedsAcceptanceCount(auditTrail.value?.measurementDeviceAuditResults)

  // Device Audit Result Count.values
  deviceInfoCount.value = getSeverityCount(auditTrail.value?.deviceAuditResults, "info")
  deviceWarningCount.value = getSeverityCount(auditTrail.value?.deviceAuditResults, "warning")
  deviceErrorCount.value = getSeverityCount(auditTrail.value?.deviceAuditResults, "error")
  deviceNeedsAcceptanceCount.value = getNeedsAcceptanceCount(auditTrail.value?.deviceAuditResults)

  // Time Series Audit Result Count.values
  timeSeriesInfoCount.value = getSeverityCount(auditTrail.value?.timeSeriesAuditResults, "info")
  timeSeriesWarningCount.value = getSeverityCount(auditTrail.value?.timeSeriesAuditResults, "warning")
  timeSeriesErrorCount.value = getSeverityCount(auditTrail.value?.timeSeriesAuditResults, "error")
  timeSeriesNeedsAcceptanceCount.value = getNeedsAcceptanceCount(auditTrail.value?.timeSeriesAuditResults)

  // Device Savings Audit Result Count.values
  deviceSavingsInfoCount.value = getSeverityCount(auditTrail.value?.deviceSavingsAuditResults, "info")
  deviceSavingsWarningCount.value = getSeverityCount(auditTrail.value?.deviceSavingsAuditResults, "warning")
  deviceSavingsErrorCount.value = getSeverityCount(auditTrail.value?.deviceSavingsAuditResults, "error")
  deviceSavingsNeedsAcceptanceCount.value = getNeedsAcceptanceCount(auditTrail.value?.deviceSavingsAuditResults)

  // Total Count.values
  totalInfoCount.value = timeSeriesInfoCount.value + deviceInfoCount.value + measurementDeviceInfoCount.value + deviceSavingsInfoCount.value
  totalWarningCount.value =
    timeSeriesWarningCount.value + deviceWarningCount.value + measurementDeviceWarningCount.value + deviceSavingsWarningCount.value
  totalErrorCount.value = timeSeriesErrorCount.value + deviceErrorCount.value + measurementDeviceErrorCount.value + deviceSavingsErrorCount.value
  totalNeedsAcceptanceCount.value =
    timeSeriesNeedsAcceptanceCount.value +
    deviceNeedsAcceptanceCount.value +
    measurementDeviceNeedsAcceptanceCount.value +
    deviceSavingsNeedsAcceptanceCount.value
}
</script>

<style scoped lang="scss">
.tab {
  @apply py-2 px-4;
  &:first-of-type:not(:only-of-type) {
    @apply -ml-4;
  }
}

.active-tab {
  @apply border-b-2 border-sagetone;
}
</style>
