<template>
  <div v-if="!!portfolio" class="min-h-[600px] rounded-md bg-white p-3 shadow-md">
    <div class="flex w-full flex-row justify-between">
      <div>
        <h1 class="text-heading-4 mb-4 max-w-xl break-words">{{ portfolio.name }}</h1>

        <table>
          <tr>
            <th scope="row">Internal ID</th>
            <td>{{ portfolio.id }}</td>
          </tr>
          <tr>
            <th scope="row">Display ID</th>
            <td class="font-mono">{{ portfolio.displayId }}</td>
          </tr>
          <tr>
            <th scope="row">Status</th>
            <td>{{ portfolio.status }}</td>
          </tr>
          <tr>
            <th scope="row">Date Range</th>
            <td>{{ portfolio.dateRange.lower }} to {{ portfolio.dateRange.upper }}</td>
          </tr>
        </table>
      </div>

      <div class="flex w-1/3 justify-end p-2">
        <img :src="Portfolio.Image(portfolio)" class="max-h-[140px] max-w-[300px]" />
      </div>
    </div>

    <div class="mt-2 flex gap-2">
      <input
        ref="logoFileInputRef"
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        class="hidden"
        :disabled="adminPortfoliosStore.isSaving"
        @input="uploadImage" />
      <WcButton
        text="Upload new image"
        icon="wc-carbon:upload"
        size="small"
        :is-disabled="adminPortfoliosStore.isSaving"
        @click="logoFileInputRef?.click()" />

      <WcButton
        text="Delete Portfolio"
        color="alert"
        icon="wc-carbon:delete"
        size="small"
        :is-disabled="adminPortfoliosStore.isSaving"
        @click="deletePortfolio()" />
    </div>

    <nav class="my-4 flex flex-row gap-2 rounded-md bg-neutral-20 p-1">
      <router-link class="tab" :to="{ name: 'wc-admin-portfolio-allocations' }">Allocations</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-portfolio-suppliers' }">Suppliers</router-link>
      <router-link v-if="portfolio.allocationSource == PortfolioAllocationSource.orders" class="tab" :to="{ name: 'wc-admin-portfolio-orders' }"
        >Orders</router-link
      >
      <router-link v-if="portfolio.allocationSource == PortfolioAllocationSource.stakes" class="tab" :to="{ name: 'wc-admin-portfolio-stakes' }"
        >Stakes</router-link
      >
      <router-link class="tab" :to="{ name: 'wc-admin-portfolio-config' }">Configuration</router-link>
    </nav>

    <router-view></router-view>
  </div>
  <div v-else>
    <p>Portfolio not found.</p>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue"
import { WcButton } from "@/components/button"
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { Portfolio, PortfolioAllocationSource } from "@common/models/order"
import { useRouter } from "vue-router"

const adminPortfoliosStore = useAdminPortfoliosStore()
const router = useRouter()
const logoFileInputRef = ref<HTMLInputElement | null>(null)

const props = defineProps<{
  portfolioId: number
}>()

watchEffect(async () => {
  // Force a fetch from the backend when viewing an individual portfolio,
  // to ensure we have the latest data in case someone else is editing
  // simultaneously.
  if (!isNaN(props.portfolioId)) {
    try {
      await adminPortfoliosStore.fetchPortfolio(props.portfolioId)
    } catch (exc) {
      // ignore
    }
  }
})

const portfolio = computed(() => adminPortfoliosStore.getPortfolioById(props.portfolioId)!)

const uploadImage = async (event: Event) => {
  const fileInput = event.target! as HTMLInputElement
  const file = fileInput.files?.[0]
  if (!file) {
    return
  }

  await adminPortfoliosStore.uploadImage(portfolio.value, file)
}

const deletePortfolio = async () => {
  if (confirm(`Delete portfolio '${portfolio.value.name}'?`)) {
    await adminPortfoliosStore.deletePortfolio(portfolio.value)
    router.push({ name: "wc-admin-portfolios" })
  }
}
</script>

<style scoped lang="scss">
table th {
  @apply text-left font-normal pr-8;
}

.tab {
  @apply px-4 py-2 rounded-md;
}

.router-link-active {
  @apply bg-neutral-30;
}
</style>
